.sliderr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
}
.summary {
  display: flex;
  width: 100%;
  margin-top: 2.5vw;
  justify-content: space-between;
  margin-bottom: 4vw;
}
.sliderBody {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.sliderBody img {
  width: 24%;
}
.leftSummary {
  border-radius: 1.5vw;
  border: 0.15625vw solid #f5f7f9;
  padding: 2.5vw;
  width: 52%;
}
.leftSummary h4 {
  color: #000;
  font-family: Montserrat;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 3.0000000000000004vw */
  letter-spacing: -0.125vw;
  margin-bottom: 1vw;
}
.leftSummary div {
  border-bottom: 0.052083333333333336vw solid rgba(168, 176, 183, 0.5);
  padding: 1.3vw 0;
}
.leftSummary div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.leftSummary div p {
  color: var(--, #626262);
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0625vw;
}
.leftSummary p span {
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.0625vw;
}
.summary > div {
}
.rightSumm {
  border-radius: 1.5vw;
  background: #f5f7f9;
  padding: 2.5vw;
  width: 45%;
}
.rightSumm h4 {
  font-family: Montserrat;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.875vw */
  letter-spacing: -0.0625vw;
  margin-bottom: 0.8vw;
}
.rightSumm p {
  color: #626262;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 2.2500000000000004vw */
  letter-spacing: -0.0625vw;
  margin-bottom: 2.5vw;
}
.rightSumm a {
  color: #6ed375;
  font-family: Inter;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.09375000000000001vw;
  text-decoration-line: underline;
}
@media (max-width: 640px) {
  .leftSummary {
    border-radius: 6.5vw;
    border: 0.15625vw solid #f5f7f9;
    padding: 6.5vw;
    width: 100%;
  }
  .leftSummary h4 {
    color: #000;
    font-family: Montserrat;
    font-size: 6.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 5vw;
  }
  .leftSummary div p {
    color: var(--, #626262);
    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .leftSummary div {
    border-bottom: 0.052083333333333336vw solid rgba(168, 176, 183, 0.5);
    padding: 3.3vw 0;
  }
  .leftSummary p span {
    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .summary {
    display: flex;
    width: 100%;
    margin-top: 2.5vw;
    justify-content: space-between;
    margin-bottom: 4vw;
    flex-direction: column;
  }
  .rightSumm {
    border-radius: 7.5vw;
    background: #f5f7f9;
    padding: 7.5vw;
    margin-top: 5vw;
    width: 100%;
  }
  .rightSumm p {
    color: #626262;
    font-family: Inter;
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 5.5vw;
  }
  .register {
    border-radius: 52.083333333333336vw;
    background: #0370f3;
    padding: 5.5vw 21.5vw;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 7vw;
  }
  .slidek {
    width: 9vw;
    display: block;
    margin-top: 1vw;
  }
  .podic {
    display: block;
    color: #626262;
    margin-top: 2vw;
    font-family: Montserrat;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.052083333333333336vw;
  }
  .rightSumm a {
    color: #6ed375;
    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
    text-decoration-line: underline;
  }
  .rightSumm h4 {
    font-family: Montserrat;
    font-size: 6.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 3.8vw;
  }
  .summary {
    display: flex;
    width: 100%;
    margin-top: 8.5vw;
    justify-content: space-between;
    margin-bottom: 4vw;
    flex-direction: column;
  }
}
