.headerTop {
  display: flex;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  justify-content: space-between;
  padding: 1.4vw 0;
}
.Header ul {
  list-style-type: none;
  display: flex;
  margin-left: 21vw;
}
.Header ul li {
  margin: 0 1vw;
}
.Header {
  position: relative;
}
.Header ul li a {
  text-align: center;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.25vw */
  letter-spacing: -0.052083333333333336vw;
}
.phone {
  position: absolute;
  left: 0;
  top: 20.3vw;
  width: 15.5vw;
}
.tab {
  position: absolute;
  right: 0;
  top: 31vw;
  width: 58vw;
  z-index: 5;
}
.tech {
  margin-left: 21vw;
  margin-top: 1.3vw;
  color: #626262;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.8vw;
  width: fit-content;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
.star {
  width: 8vw;
  margin-top: 0.3vw;
  margin-left: 22.7vw;
}
.footerTop {
  display: flex;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
}
.footerTop .signUp {
  margin-left: 2vw;
}
.footerTop .footerTop a {
}
.open img {
  margin-left: 0.8vw;
  width: 1.7vw;
}
.open {
  display: flex;
  align-items: center;
  border-radius: 52.083333333333336vw;
  background: #0370f3;
  color: #fff;
  cursor: pointer;
  width: fit-content;
  margin-left: 18vw;
  padding: 1.5vw 3.5vw;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.2vw;
  margin-top: 5.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.5000000000000002vw */
  letter-spacing: -0.052083333333333336vw;
}
.depend {
  color: #626262;
  font-family: Montserrat;
  font-size: 1.9vw;
  font-style: normal;
  margin-left: 18vw;
  margin-top: 3vw;
  font-weight: 500;
  line-height: 150%; /* 2.8125vw */
  letter-spacing: -0.09375000000000001vw;
}
.Header h2 {
  color: #000;
  font-family: Inter;
  font-size: 5vw;
  margin-left: 18vw;
  margin-top: 8.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 6.25vw */
  letter-spacing: -0.26041666666666663vw;
}
.all {
  color: #626262;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.9vw;
  margin-top: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 2.2500000000000004vw */
  letter-spacing: -0.09375000000000001vw;
}
.hots {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.5vw;
}
.hotEm {
  padding: 0.7vw 0.8vw;
  border-radius: 52.083333333333336vw;
  background: #e2e8ee;
  display: flex;
  align-items: center;
  margin: 0 0.75vw;
  color: #72879c;
  text-align: center;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.25vw */
  letter-spacing: -0.052083333333333336vw;
}
.hotEm img {
  width: 1.6vw;
  margin-right: 0.5vw;
}
a {
  text-decoration: none;
  color: black;
}
ul a:hover {
  text-decoration: underline;
}
.open {
  transition: 0.3s;
}
.open:hover {
  background: #024eac;
}
h1 {
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 6vw;
  font-style: normal;
  font-weight: 600;
  z-index: 5;
  position: relative;
  line-height: 120%;
  letter-spacing: -0.25vw;
  margin-top: 4.5vw;
}
.signUp:hover,
.login:hover {
  letter-spacing: 0.1vw;
}
.signUp,
.login {
  transition: 0.3s;
}
h1 .etern {
  color: #0370f3;
  margin-right: 0.8vw;
}
.signUp {
  margin-left: auto;
  font-size: 1vw;
  padding: 0.8vw 2vw;
  border-radius: 52.083333333333336vw;
  background: #0370f3;
  color: #fff;
  font-weight: 600;
  font-family: Montserrat;
  cursor: pointer;
}
.login {
  margin-left: 1vw;
  color: rgba(3, 112, 243, 0.3);
  text-align: center;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 1vw;
  padding: 0.8vw 2vw;
  background: rgba(3, 112, 243, 0.15);
  border-radius: 52.083333333333336vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.25vw */
  letter-spacing: -0.052083333333333336vw;
}
.flexImg {
  display: flex;
  margin-left: 18vw;
  margin-top: 2.8vw;
  align-items: flex-start;
}
.res {
  width: 13vw;

  display: block;
}
.circles {
  margin-left: 2.5vw;
  margin-top: 1vw;
  width: 16vw;
}
.soon {
  width: 100%;
  margin-top: 3vw;
  margin-bottom: 3vw;
}
.logo {
  width: 13.5vw;
}
.docs {
  text-align: center;
  font-family: Montserrat;
  font-size: 1vw;
  margin: 0 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.25vw */
  letter-spacing: -0.052083333333333336vw;
}
.links img {
  width: 1.8vw;
  margin: 0 0.5vw;
}
.footerTop .login {
  margin-right: 23vw;
}
.adress {
  display: flex;

  padding-bottom: 3vw;
  width: 90vw;
  margin: 0 auto;
  margin-top: 2.5vw;
}
.cir {
  position: absolute;
  right: 0;
  top: 65vw;
}
.adress p {
  margin-right: 3vw;
}
.adress p span {
  font-weight: bold;
  margin-right: 1vw;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: #d0f8d3;
  animation: ripple 15s ease-in-out infinite;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw 0.052083333333333336vw #d0f8d3;
}

.small {
  width: 15.416666666666668vw;
  height: 15.416666666666668vw;
  left: -5.208333333333334vw;
  bottom: -5.208333333333334vw;
}

.medium {
  width: 35.833333333333336vw;
  height: 35.833333333333336vw;
  left: -10.416666666666668vw;
  bottom: -10.416666666666668vw;
}

.large {
  width: 45.25vw;
  height: 45.25vw;
  left: -15.625vw;
  bottom: -15.625vw;
}

.xlarge {
  width: 65.66666666666667vw;
  height: 65.66666666666667vw;
  left: -20.833333333333336vw;
  bottom: -20.833333333333336vw;
}

.xxlarge {
  width: 75.083333333333336vw;
  height: 75.083333333333336vw;
  left: -26.041666666666668vw;
  bottom: -26.041666666666668vw;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(0.9);
  }
}
@media (max-width: 640px) {
  h1 {
    flex-direction: column;
    letter-spacing: 0.088125vw;
    font-size: 17vw;
  }
  .all {
    color: #626262;
    text-align: center;
    font-family: Montserrat;
    font-size: 5.9vw;
    margin-top: 3.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .hotEm img {
    width: 7.6vw;
    margin-right: 0;
    margin-bottom: 2vw;
  }
  .hotEm {
    flex-direction: column;
    align-items: center;
  }
  .open {
    display: flex;
    align-items: center;
    border-radius: 52.083333333333336vw;
    background: #0370f3;
    color: #fff;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 0;
    margin: 3vw auto;
    margin-top: 12vw;
    padding: 6.5vw 15.5vw;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.2vw;

    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.052083333333333336vw;
  }
  .hotEm {
    padding: 3vw 3vw;
    border-radius: 3vw;
    background: #e2e8ee;
    display: flex;
    width: 20vw;
    align-items: center;
    margin: 0 2vw;
    color: #72879c;
    text-align: center;
    font-family: Montserrat;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .tech {
    margin-left: 0;
    margin-top: 1.3vw;
    color: #626262;
    text-align: center;
    font-family: Montserrat;
    font-size: 3.5vw;
    width: -moz-fit-content;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 auto;
    letter-spacing: -0.052083333333333336vw;
  }
  .star {
    width: 29vw;
    margin-top: 0.3vw;
    margin-left: 0;
    margin: 0 auto;
    display: block;
    margin-top: 2vw;
  }
  .hots {
    margin-top: 5vw;
  }
  .phone {
    display: none;
  }
  .tab {
    position: absolute;
    right: 0;
    top: 176vw;
    width: 96vw;
    z-index: 5;
  }
  .res {
    width: 35vw;
    display: block;
  }
  .flexImg {
    display: flex;
    margin-left: 60vw;
    margin-top: -50.5vw;
    align-items: flex-start;
  }
  .circles {
    display: none;
  }
  .star {
    width: 29vw;
    margin-top: 0.3vw;
    margin-left: 0;
    margin: 0 auto;
    display: block;
    margin-top: 2vw;
    margin-bottom: 130vw;
  }
  .footerTop {
    flex-direction: column;
    margin-top: 10vw;
  }
  .logo {
    width: 66.5vw;
    margin-bottom: 11vw;
  }
  .footerTop .signUp {
    margin-left: 0;
  }
  .signUp {
    margin-left: auto;
    font-size: 5vw;
    padding: 4.8vw 18vw;
    border-radius: 52.083333333333336vw;
    background: #0370f3;
    color: #fff;
    font-weight: 600;
    font-family: Montserrat;
    cursor: pointer;
  }
  .links img {
    width: 10.8vw;
    margin: 1vw;
  }
  .adress {
    flex-direction: column;
    padding-bottom: 15vw;
  }
  .adress p {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5vw;
  }
  .adress p span {
    margin-bottom: 2vw;
  }
  .docs {
    text-align: center;
    font-family: Montserrat;
    font-size: 5vw;
    margin: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .login {
    margin-left: 0;
    color: rgba(3, 112, 243, 0.3);
    text-align: center;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 6vw;
    margin-top: 4vw;
    padding: 4.8vw 20vw;
    background: rgba(3, 112, 243, 0.15);
    border-radius: 52.083333333333336vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 10vw;
  }
  .footerTop .login {
    margin-right: 0;
  }
  .Header ul {
    display: none;
  }
  .signUp {
    display: none;
  }
  .login {
    display: none;
  }
  .headerTop .logo {
    width: 40vw !important;
    margin-bottom: 0 !important;
  }
  .headerTop {
    padding: 5vw 5vw;
    width: 100vw;
    border-bottom: 0.052083333333333336vw solid #e8edf2;
  }
  .mobileButton {
    width: 10vw;
    height: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    background: #0370f3;
    border-radius: 1vw;
    justify-content: space-around;
  }
  .mobileButton div {
    width: 100%;
    background: #fff;
    height: 0.4vw;
  }
  .open img {
    margin-left: 1.8vw;
    width: 6.7vw;
  }
  .cir {
    display: none;
  }
}
