.tera {
  display: flex;
  width: 90vw;
  margin: 4vw auto;
  margin-bottom: 0;
  align-items: flex-start;
}
.tera > img {
  margin-top: 8.5vw;
  width: 19.2vw;
  margin-left: 4.5vw;
}
.inp {
  width: 57.5vw;
}
.flexis {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  justify-content: space-between;
}
.att {
  display: flex;
  align-items: flex-start;
  margin-top: 4vw;
}
.att span {
  width: 80%;
  color: #a8b0b7;
  font-family: Montserrat;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
.att img {
  width: 2.3vw;
}
.att b {
  color: black;
}
.flexis h2 {
  text-align: center;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 6.25vw */
  letter-spacing: -0.20833333333333334vw;
}
.flexis input {
  border-radius: 0.5vw;
  background: #f5f7f9;
  padding: 0.8vw 1.5vw;
  color: var(--, #a8b0b7);
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1.0416666666666665vw */
  letter-spacing: -0.052083333333333336vw;
  border: none;
  outline: none;
  width: 23vw;
}
#kolom {
  width: 57.5vw;
}
.numsic {
  width: 57.5vw;
  display: flex;
  justify-content: space-between;
}
.linik {
  width: 0.10416666666666667vw;
  background: #a8b0b7;
  height: 0.5vw;
  margin-bottom: 0.3vw;
}
.numsic > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1vw;
}
input[type="range"] {
  height: 1.9270833333333335vw;
  -webkit-appearance: none;
  margin: 0.5208333333333333vw 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1.9270833333333335vw;
  cursor: pointer;
  animate: 0.2s;

  background: #71d578;
  border-radius: 0.26041666666666663vw;
  border: 0.20833333333333334vw solid #f2f3f5;
}
input[type="range"]::-webkit-slider-thumb {
  border: 0.052083333333333336vw solid #ffffff;
  height: 3.125vw;
  width: 3.125vw;
  border-radius: 0.552083333333333336vw;
  background: url(./img/th.svg);
  background-size: cover;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.8333333333333334vw;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #71d578;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1.9270833333333335vw;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw #353535;
  background: #71d578;
  border-radius: 0.26041666666666663vw;
  border: 0.20833333333333334vw solid #f2f3f5;
}
input[type="range"]::-moz-range-thumb {
  border: 0.052083333333333336vw solid #ffffff;
  height: 3.125vw;
  width: 3.125vw;
  border-radius: 0.052083333333333336vw;
  background: #fff6f6;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 1.9270833333333335vw;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #71d578;
  border: 0.20833333333333334vw solid #f2f3f5;
  border-radius: 0.052083333333333336vw;
}
input[type="range"]::-ms-fill-upper {
  background: #71d578;
  border: 0.20833333333333334vw solid #f2f3f5;
  border-radius: 0.052083333333333336vw;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw #353535;
}
input[type="range"]::-ms-thumb {
  margin-top: 0.052083333333333336vw;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw #353535;
  border: 0.052083333333333336vw solid #ffffff;
  height: 3.125vw;
  width: 3.125vw;
  border-radius: 0.052083333333333336vw;
  background: #fff6f6;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #71d578;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #71d578;
}
.inden {
  display: flex;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09114583333333333vw;
  margin-top: 1.5vw;
  margin-bottom: 2vw;
}
.inden img {
  margin: 0 1vw;
  width: 3.5vw;
}
.flexDay {
  display: flex;
  justify-content: space-between;
  width: 57.5vw;
  margin-bottom: 2.5vw;
}
.gridEm {
  border-radius: 1.5vw;
  padding: 1.8vw 2.5vw;
  background: #fff;
  box-shadow: 0.052083333333333336vw 0.78125vw 1.5625vw 0.052083333333333336vw
    rgba(112, 151, 199, 0.2);
}
.gridEm h4 {
  font-family: Montserrat;
  font-size: 1.25vw;
  font-style: normal;
  margin-bottom: 1vw;
  font-weight: 600;
  line-height: 100%; /* 1.3451562500000003vw */
  letter-spacing: -0.06723958333333334vw;
}
.gridEm p {
  color: #000;
  font-family: Inter;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.08854166666666666vw;
  margin-bottom: 1vw;
}
.gridEm p span {
  color: #a8b0b7;
  margin-right: 0.5vw;
}
.gridEm > span {
  color: #a8b0b7;
  font-family: Inter;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
@media (max-width: 640px) {
  .Header h2 {
    color: #000;
    font-family: Inter;
    font-size: 13vw;
    margin-left: 5vw;
    margin-top: 8.7vw;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.052083333333333336vw;
  }
  .depend {
    color: #626262;
    font-family: Montserrat;
    font-size: 4.5vw;
    font-style: normal;
    margin-left: 5vw;
    margin-top: 3vw;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
  }
  .tera {
    flex-direction: column;
    margin-top: 15vw;
  }
  .flexis {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 7vw;
  }
  .flexis h2 {
    text-align: center;
    font-family: Montserrat;
    font-size: 11vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
    margin-right: 1.8vw;
    margin-bottom: 5vw;
  }
  .inp {
    width: 100%;
  }
  .flexis input {
    border-radius: 3.5vw;
    background: #f5f7f9;
    padding: 2.8vw 3.5vw;
    color: var(--, #a8b0b7);
    font-family: Inter;
    width: 100%;
    font-size: 5.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    border: none;

    outline: none;
  }
  #kolom {
    width: 100%;
  }
  .numsic {
    width: 100%;
  }
  input[type="range"] {
    height: 4.927083vw;
    -webkit-appearance: none;
    margin: 0.5208333333333333vw 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4.9270833333333335vw;
    cursor: pointer;
    animate: 0.2s;

    background: #71d578;
    border-radius: 0.26041666666666663vw;
    border: 0.20833333333333334vw solid #f2f3f5;
  }
  input[type="range"]::-webkit-slider-thumb {
    border: 0.052083333333333336vw solid #ffffff;
    height: 9.125vw;
    width: 9.125vw;
    border-radius: 0.552083333333333336vw;
    background: url(./img/th.svg);
    background-size: cover;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2.3333333333333334vw;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #71d578;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 1.9270833333333335vw;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0.052083333333333336vw 0.052083333333333336vw
      0.052083333333333336vw #353535;
    background: #71d578;
    border-radius: 0.26041666666666663vw;
    border: 0.20833333333333334vw solid #f2f3f5;
  }
  input[type="range"]::-moz-range-thumb {
    border: 0.052083333333333336vw solid #ffffff;
    height: 3.125vw;
    width: 3.125vw;
    border-radius: 0.052083333333333336vw;
    background: #fff6f6;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 1.9270833333333335vw;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #71d578;
    border: 0.20833333333333334vw solid #f2f3f5;
    border-radius: 0.052083333333333336vw;
  }
  input[type="range"]::-ms-fill-upper {
    background: #71d578;
    border: 0.20833333333333334vw solid #f2f3f5;
    border-radius: 0.052083333333333336vw;
    box-shadow: 0.052083333333333336vw 0.052083333333333336vw
      0.052083333333333336vw #353535;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 0.052083333333333336vw;
    box-shadow: 0.052083333333333336vw 0.052083333333333336vw
      0.052083333333333336vw #353535;
    border: 0.052083333333333336vw solid #ffffff;
    height: 3.125vw;
    width: 3.125vw;
    border-radius: 0.052083333333333336vw;
    background: #fff6f6;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #71d578;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #71d578;
  }
  .linik {
    width: 0.10416666666666667vw;
    background: #a8b0b7;
    height: 2.5vw;
    margin-bottom: 0.3vw;
  }
  .gridDay {
    display: flex;
    overflow-x: scroll;
    padding: 8vw 2.5vw;
    width: 100vw;
    margin-left: -5vw;
  }
  .gridDay::-webkit-scrollbar {
    display: none;
  }
  .gridEm h4 {
    font-family: Montserrat;
    font-size: 5.25vw;
    font-style: normal;
    margin-bottom: 4vw;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
  }
  .inden {
    display: flex;
    align-items: center;
    color: #000;
    justify-content: center;
    font-family: Inter;
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 5.5vw;
    margin-bottom: 2vw;
  }
  .att img {
    width: 11.3vw;
  }
  .att span {
    width: 90%;
    color: #a8b0b7;
    font-family: Montserrat;
    font-size: 3vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .tera > img {
    margin-top: 9.5vw;
    width: 100%;
    margin-left: 0;
    margin-bottom: 5vw;
  }
  .att {
    margin-top: 0;
  }
  .inden img {
    margin: 0 2vw;
    width: 7.5vw;
  }
  .flexDay {
    width: fit-content;
  }
  .gridEm p {
    color: #000;
    font-family: Inter;
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 3vw;
  }
  .gridEm {
    min-width: 30vw;
  }
  .gridEm {
    border-radius: 3.5vw;
    padding: 3.8vw 3.5vw;
    margin: 0 2.5vw;
    background: #fff;
    box-shadow: 0.052083333333333336vw 0.78125vw 1.5625vw 0.052083333333333336vw
      rgba(112, 151, 199, 0.2);
  }
  .gridEm > span {
    color: #a8b0b7;
    font-family: Inter;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 500;
    display: block;
    margin-top: 4vw;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
}
