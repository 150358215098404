.work {
  width: 90vw;
  margin: 4vw auto;
  margin-top: -4vw;
}
.workBody {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.rightWork {
  position: relative;
  margin-right: -5vw;
}
.rightWork img {
  position: relative;

  width: 64vw;
}
.leftWork h2 {
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  margin-top: 14vw;
  font-weight: 600;
  line-height: 120%; /* 6.25vw */
  letter-spacing: -0.26041666666666663vw;
  margin-bottom: 3.5vw;
}
.leftWork p {
  color: #0370f3;
  font-family: Montserrat;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 2.8125vw */
  letter-spacing: -0.09375000000000001vw;
}
.leftWork h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 3.0000000000000004vw */
  letter-spacing: -0.125vw;
  margin-bottom: 4.5vw;
}
.leftWork h3 span {
  color: #000;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 6.000000000000001vw */
  letter-spacing: -0.25vw;
}
.leftWork div {
  color: #626262;
  font-family: Montserrat;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 2.8125vw */
  letter-spacing: -0.09375000000000001vw;
}
.fleetMine {
  border-radius: 1.5vw;
  background: #f5f7f9;
  padding: 3.8vw 2.7vw;
  position: relative;
  margin-top: 8vw;
}
.fleetMine img {
  position: absolute;
  width: 21.5vw;
  right: 3.8vw;
  bottom: 0;
}
.fleetMine p {
  color: #000;
  font-family: Montserrat;
  font-size: 2.5vw;
  font-style: normal;
  margin-bottom: 1vw;
  font-weight: 600;
  line-height: 120%; /* 3.0000000000000004vw */
  letter-spacing: -0.125vw;
}
.fleetMine span {
  color: #626262;
  font-family: Montserrat;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.09375000000000001vw;
}

@media (max-width: 640px) {
  .workBody {
    flex-direction: column;
  }
  .leftWork h2 {
    font-family: Montserrat;
    font-size: 12vw;
    font-style: normal;
    margin-top: 14vw;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.10416666666666667vw;
    margin-bottom: 3.5vw;
  }
  .leftWork p {
    color: #0370f3;
    font-family: Montserrat;
    font-size: 5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
  }
  .leftWork h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 6.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.07291666666666666vw;
    margin-bottom: 10.5vw;
  }
  .leftWork h3 span {
    color: #000;
    font-family: Montserrat;
    font-size: 12vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .leftWork div {
    color: #626262;
    font-family: Montserrat;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 9vw;
  }
  .fleetMine {
    border-radius: 5.5vw;
    background: #f5f7f9;
    padding: 4.8vw 5.7vw;
    position: relative;
    margin-top: 8vw;
    padding-bottom: 0;
  }
  .sliderr {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    margin: 0 auto;
    margin-top: 7vw;
  }

  .sliderBody {
    display: flex;
    width: 100vw;
    margin-left: 0vw;
    justify-content: space-between;
    overflow-x: scroll;
    padding: 0 2.5vw;
  }
  .sliderBody::-webkit-scrollbar {
    display: none;
  }
  .sliderBody img {
    width: 59vw;
  }
  .fleetMine span {
    color: #626262;
    font-family: Montserrat;
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 500;
    margin-top: 5vw;
    display: block;
    line-height: normal;
    letter-spacing: 0.052083333333333336vw;
  }
  .fleetMine p {
    color: #000;
    font-family: Montserrat;
    font-size: 8.5vw;
    font-style: normal;
    margin-bottom: 1vw;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .fleetMine img {
    position: static;
    width: 100%;
    margin-top: 5vw;
    bottom: 0;
  }
  .rightWork {
    position: relative;
    margin-right: 0;
  }
  .rightWork {
    position: relative;
    margin-right: 0;
    left: -5vw;
  }
  .rightWork img {
    position: relative;
    width: 100vw;
  }
}
