@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.container {
  width: 83vw;
  margin-left: 17vw;
  padding-left: 2.5vw;
  padding-right: 0;
  padding-bottom: 0;
  margin-top: 7vw;
}
*::-webkit-scrollbar {
  width: 18px; /* ширина scrollbar */
}
*::-webkit-scrollbar-track {
  background: #ededed; /* цвет дорожки */
}
*::-webkit-scrollbar-thumb {
  background-color: #0370f3; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
  border: 2px solid #ededed; /* padding вокруг плашки */
}
.App {
  overflow: hidden;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #a8b0b7;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a8b0b7;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a8b0b7;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8b0b7;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a8b0b7;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #a8b0b7;
}
