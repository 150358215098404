.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: 4vw auto;
}
.centerTop {
  display: flex;
  align-items: center;
  margin-bottom: 7vw;
}
.centerTop img {
  width: 9.5vw;
}
.centerTop h2 {
  margin-left: 4vw;

  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 6.000000000000001vw */
  letter-spacing: -0.2vw;
}
.centerTop p {
  color: #626262;
  font-family: Montserrat;
  font-size: 1.875vw;
  font-style: normal;
  font-weight: 400;
  width: 44vw;
  margin-left: 3vw;
  padding-left: 3vw;
  border-left: 0.052083333333333336vw solid #f5f7f9;
  line-height: 180%; /* 3.3749999999999996vw */
  letter-spacing: -0.075vw;
}
.centerCont {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.centerCont > img {
  width: 29vw;
}
.rightCenter h3 {
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.26041666666666663vw;
}
.rightCenter h3 span {
  color: #0370f3;
}
.rightCenter {
  margin-left: 14.5vw;
}
.rightCenter > p {
  display: flex;
  align-items: center;
  color: #626262;
  font-family: Montserrat;
  font-size: 1.8vw;
  font-style: normal;
  margin-bottom: 2.3vw;
  font-weight: 500;
  line-height: 180%; /* 3.3749999999999996vw */
  letter-spacing: -0.09375000000000001vw;
}
.rightCenter > p img {
  position: relative;
  left: -4vw;
  width: 12vw;
}
.rightCenter ul {
  list-style-type: none;
}
.rightCenter li {
  display: flex;
  align-items: center;
  padding: 1vw 0;
  width: 40vw;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 1.875vw */
  letter-spacing: -0.052083333333333336vw;
}
.rightCenter li img {
  width: 3.5vw;
  margin-right: 1vw;
}
.button {
  display: flex;
  align-items: center;
  margin-top: 2vw;
}
.registration {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.3vw;
  border-radius: 52.083333333333336vw;
  background: #0370f3;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.5000000000000002vw */
  letter-spacing: -0.0625vw;
  padding: 1.3vw 2.5vw;
  margin-right: 1.5vw;
  cursor: pointer;
}
.button p {
  color: #626262;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Montserrat;
  font-size: 0.8vw;

  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 1vw */
  letter-spacing: -0.052083333333333336vw;
}
@media (max-width: 640px) {
  .centerTop {
    display: flex;
    align-items: flex-start;
    margin-bottom: 7vw;
    width: 100%;
    flex-direction: column;
  }
  .centerTop img {
    width: 16.5vw;
  }
  .centerTop h2 {
    margin-left: 0;
    font-family: Montserrat;
    font-size: 10vw;
    font-style: normal;
    font-weight: 600;
    margin-top: 4vw;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .centerTop p {
    color: #626262;
    font-family: Montserrat;
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 400;
    width: 90vw;
    margin-left: 0;
    padding-left: 0;
    border-left: 0.052083333333333336vw solid #f5f7f9;
    line-height: 180%;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 7vw;
  }
  .rightCenter {
    margin-left: 0;
  }
  .rightCenter > p img {
    display: none;
  }
  .rightCenter > p {
    display: flex;
    align-items: center;
    color: #626262;
    font-family: Montserrat;
    font-size: 5.5vw;
    font-style: normal;
    margin-bottom: 2.3vw;
    font-weight: 500;
    margin-top: 5vw;
    line-height: 180%;
    letter-spacing: -0.052083333333333336vw;
  }
  .rightCenter li {
    display: flex;
    align-items: center;
    padding: 3vw 0;
    width: 100%;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: -0.052083333333333336vw;
  }
  .rightCenter ul {
    list-style-type: none;
    margin-top: 6vw;
  }
  .button {
    flex-direction: column;
    margin-top: 6vw;
  }
  .registration {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.3vw;
    border-radius: 52.083333333333336vw;
    background: #0370f3;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
    padding: 5.3vw 12.5vw;
    margin-right: 0;
    cursor: pointer;
  }
  .button p img {
    margin-top: 2vw;
  }
  .centerCont > img {
    width: 100vw;
    margin-left: -5vw;
  }

  .flexSteps {
    flex-direction: column;
  }
  .button p {
    color: #626262;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Montserrat;
    font-size: 3.8vw;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    margin-top: 4vw;
    line-height: 120%;
    margin-bottom: 3vw;
    align-items: center;
    letter-spacing: -0.052083333333333336vw;
  }
  .rightCenter li img {
    width: 10.5vw;
    margin-right: 4vw;
  }
  .rightCenter h3 {
    font-family: Montserrat;
    font-size: 12vw;
    font-style: normal;
    margin-top: 5vw;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.052083333333333336vw;
  }
  .centerCont {
    flex-direction: column-reverse;
  }
}
