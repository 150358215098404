.creator {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vw;
  margin-top: 4vw;
}
.leftCreator h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 5.729166666666666vw */
  letter-spacing: -0.20833333333333334vw;
}
.leftCreator {
  width: 51%;
}
.greenC {
  color: #2e7634;
  font-family: Montserrat;
  padding: 3.2vw;
  border-radius: 1.5vw;
  margin: 2.5vw 0;
  background: #d0f8d3;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 3.1875vw */
  letter-spacing: -0.07083333333333333vw;
}
.blueC {
  color: #284669;
  padding: 3.2vw;
  border-radius: 1.5vw;
  background: #b4d5fc;
  font-family: Montserrat;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 3.1875vw */
  letter-spacing: -0.07083333333333333vw;
}
.leftCreator > p {
  color: #626262;
  font-family: Montserrat;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 3.3749999999999996vw */
  letter-spacing: -0.075vw;
  margin-top: 2vw;
}
.rightCreator img {
  width: 100%;
  margin-bottom: 3vw;
  margin-top: 1.5vw;
}
.rightCreator {
  width: 41vw;
}
.grayC {
  border-radius: 2vw;
  background: #f5f7f9;
  padding: 3.2vw;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 3.125vw */
  letter-spacing: -0.10416666666666667vw;
}
@media (max-width: 640px) {
  .creator {
    flex-direction: column;
  }
  .leftCreator {
    width: 100%;
  }
  .leftCreator h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 10vw;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.052083333333333336vw;
  }
  .leftCreator > p {
    color: #626262;
    font-family: Montserrat;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 5vw;
    margin-bottom: 6vw;
  }
  .greenC {
    color: #2e7634;
    font-family: Montserrat;
    padding: 6.2vw;
    border-radius: 5vw;
    margin: 2.5vw 0;
    background: #d0f8d3;
    font-size: 6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: -0.07083333333333333vw;
  }
  .blueC {
    color: #284669;
    padding: 6.2vw;
    border-radius: 5vw;
    background: #b4d5fc;
    font-family: Montserrat;
    font-size: 6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-top: 7vw;
    letter-spacing: -0.07083333333333333vw;
  }
  .rightCreator {
    width: 100%;
    margin-top: 6vw;
  }
  .grayC {
    border-radius: 5vw;
    background: #f5f7f9;
    padding: 5.2vw 16vw;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 6vw;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
  }
}
