.steps {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 4vw;
}
.steps h2 {
  font-family: Montserrat;
  font-size: 5vw;
  margin: 5vw 0;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 5.729166666666666vw */
  letter-spacing: -0.20833333333333334vw;
}
.stepsEm div {
  border: 0.15625vw solid #0370f3;
  border-radius: 50%;
  width: 3.5vw;
  height: 3.5vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 2.0625vw */
  letter-spacing: -0.09375000000000001vw;
}
.stepsEm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2.5vw;
  position: relative;
  z-index: 2;
  pointer-events: none;
}
.flexSteps {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 7vw;
}
.stepsEm p {
  color: #626262;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  margin-top: 1.5vw;
  font-weight: 500;
  line-height: 150%; /* 2.34375vw */
  letter-spacing: -0.0625vw;
}
.box {
  --border-size: 0.15625vw;
  --border-angle: 0turn;
  width: 80%;
  height: 16.7vw;
  position: absolute;
  top: 17.2vw;
  background-image: conic-gradient(
      from var(--border-angle),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 50%,
      #86c1fc,
      rgb(62, 164, 254)
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: bg-spin 9s linear infinite;
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
/* .box:hover {
  animation-play-state: paused;
} */
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
.weW {
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5vw;
  margin-bottom: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 2.34375vw */
  letter-spacing: -0.0625vw;
}
.weW b {
  color: #0370f3;
}
.urrent {
  color: #626262;
  font-family: Montserrat;
  font-size: 1.5vw;
  width: 75%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4vw;
  line-height: 150%;
  letter-spacing: -0.0625vw;
}
.flexIn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5vw;
}
.flexIn p {
  color: #fff;
  font-family: Montserrat;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 3.28125vw */
  letter-spacing: -0.08750000000000001vw;
  border-radius: 2vw;
  background: #6ed375;
  padding: 3.5vw;
  width: 42vw;
  text-align: center;
}
.flexIn div {
  text-align: center;
  font-family: Montserrat;
  text-align: center;
  font-size: 2.2vw;
  width: 42vw;
  font-style: normal;
  border-radius: 2vw;
  background: #f5f7f9;
  font-weight: 500;
  padding: 3.5vw;
  line-height: 150%; /* 3.28125vw */
  letter-spacing: -0.08750000000000001vw;
}
.register {
  border-radius: 52.083333333333336vw;
  background: #0370f3;
  padding: 1.5vw 5.5vw;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.5000000000000002vw */
  letter-spacing: -0.0625vw;
}
.podic {
  display: block;
  color: #626262;
  margin-top: 1vw;
  font-family: Montserrat;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 1vw */
  letter-spacing: -0.052083333333333336vw;
}
.slidek {
  width: 3vw;
  display: block;
  margin-top: 0.5vw;
}
@media (max-width: 640px) {
  .steps h2 {
    font-family: Montserrat;
    font-size: 10vw;
    margin: 5vw 0;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 0;
    width: 100%;
  }
  .urrent {
    color: #626262;
    font-family: Montserrat;
    font-size: 4.5vw;
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 4vw;
    line-height: 150%;
    margin-top: 6vw;
    letter-spacing: -0.052083333333333336vw;
  }
  .flexIn p {
    color: #fff;
    font-family: Montserrat;
    font-size: 5.2vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.08750000000000001vw;
    border-radius: 5vw;
    background: #6ed375;
    padding: 6.5vw;
    width: 100%;
    text-align: center;
  }
  .podic {
    display: block;
    color: #626262;
    margin-top: 2vw;
    font-family: Montserrat;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .slidek {
    width: 11vw;
    display: block;
    margin-top: 1.5vw;
  }
  .register {
    border-radius: 52.083333333333336vw;
    background: #0370f3;
    padding: 5.5vw 25.5vw;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
  }
  .flexIn div {
    text-align: center;
    font-family: Montserrat;
    text-align: center;
    font-size: 5.2vw;
    width: 100%;
    font-style: normal;
    border-radius: 5vw;
    background: #f5f7f9;
    font-weight: 500;
    padding: 7vw 3.5vw;
    margin-top: 5vw;
    line-height: 150%;
    letter-spacing: -0.08750000000000001vw;
  }
  .flexIn {
    flex-direction: column;
    margin-top: 5vw;
  }
  .weW {
    text-align: center;
    font-family: Montserrat;
    font-size: 5.5vw;
    margin-bottom: 4vw;
    font-style: normal;
    font-weight: 500;
    margin-top: 5vw;
    line-height: 150%;
    letter-spacing: -0.0625vw;
  }
  .stepsEm {
    flex-direction: row;
    margin-bottom: 5vw;
    margin-left: 0;
  }
  .stepsEm div {
    border: 0.15625vw solid #0370f3;
    border-radius: 50%;
    width: 12.5vw;
    height: 12.5vw;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-size: 5.8vw;
    font-style: normal;
    font-weight: 600;
    margin-right: 3vw;
    line-height: 110%;
    letter-spacing: 0.052083333333333336vw;
  }
  .box {
    display: none;
  }
  .flexSteps {
    margin-bottom: 0;
  }
  .stepsEm p {
    color: #626262;
    text-align: left;
    font-family: Montserrat;
    font-size: 3.5vw;
    font-style: normal;
    margin-top: 0;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.0625vw;
  }
}
